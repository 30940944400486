const STAGING = "dev"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "xxqwjd9rs6mhpxm8j4c0ocf52kt3ekf3b23bs1t0ienniq30",
  dev: "xxqwjd9rs6mhpxm8j4c0ocf52kt3ekf3b23bs1t0ienniq30",
  prod: "omkjpeeljai4s7i4zimgk24v82bud1wslchehtgqgc8kjate",
  preview: "zjczddcqk4ax0s2egwhzcnlzs1e4am1kwqpd8g6xdy5i6q0k",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}